import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import SimpleReactLightbox from 'simple-react-lightbox'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { resolvers, typeDefs } from './resolvers'

const errorLink = onError(({ graphQLErrors, networkError }): any => {
  const ignoredErrors = ['Bad credentials']
  // @ts-ignore
  if (graphQLErrors && graphQLErrors[0].message) {
    // @ts-ignore
    if (graphQLErrors[0].message === 'You need to log in') {
      window.localStorage.clear()
      return window.location.reload()
    }
    // @ts-ignore
    if (!ignoredErrors.includes(graphQLErrors[0].message)) {
      alert(graphQLErrors[0].message)
    }
  } else {
    alert(`Status code: 500 something went wrong`)
    console.log(graphQLErrors)
  }
})

const link = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}graphql`,
})

let token: string

const authLink = setContext((_, { headers }) => {
  if (!token) {
    const loginData = localStorage.getItem('loginData')
    token = loginData ? JSON.parse(loginData).token : ''
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  // @ts-ignore
  link: errorLink.concat(authLink.concat(link)),
  typeDefs,
  resolvers,
  cache: new InMemoryCache(),
})

const render = (Component: React.FC) =>
  ReactDOM.render(
    <ApolloProvider client={client}>
      <SimpleReactLightbox>
        <Router>
          <Component />
        </Router>
      </SimpleReactLightbox>
    </ApolloProvider>,
    document.getElementById('root')
  )

render(App)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
