import React from 'react'
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router'

import { backgroundGray, navy } from '../../layout/colors'
import { FoodType } from '../canteen/Food'
import removeFood from '../../mutations/removeFood'
import foodAdmin from '../../queries/foodAdmin'

interface Props {
  item: FoodType
}

const Main = ({ item }: Props) => {
  const history = useHistory()

  const refetchQueries = { refetchQueries: [{ query: foodAdmin }] }
  const [req] = useMutation(removeFood, {
    ...refetchQueries,
  })

  function handleDelete() {
    return req({ variables: { id: item.id } })
  }

  return (
    <Row>
      <Td>{item.name}</Td>
      <Td onClick={() => history.push(`/food/${item.id}/edit`)}>
        <Edit />
      </Td>
      <Td>
        <Delete
          onClick={() => {
            handleDelete()
          }}
        />
      </Td>
    </Row>
  )
}

export default Main

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px;
  cursor: pointer;

  &:nth-last-child(1) {
    text-align: right;
    width: 34px;
  }
  &:nth-last-child(2) {
    text-align: right;
  }
`

const Edit = styled(EditIcon)`
  color: ${navy};
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
`
