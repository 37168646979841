import React from 'react'
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useMutation } from '@apollo/client'

import { backgroundGray, navy } from '../../layout/colors'
import { useHistory } from 'react-router'
import removeCanteen from '../../mutations/removeCanteen'
import canteens from '../../queries/canteens'

interface CanteenType {
  id: number
  name?: string
  region?: string
}

interface Props {
  item: CanteenType
}

const Main = ({ item }: Props) => {
  const history = useHistory()

  const refetchQueries = { refetchQueries: [{ query: canteens }] }
  const [req] = useMutation(removeCanteen, {
    ...refetchQueries,
  })

  function handleDelete() {
    return req({ variables: { id: item.id } })
  }

  return (
    <Row>
      <Td>{item.name}</Td>
      <Td>{item.region}</Td>
      <Td onClick={() => history.push(`/addFood/${item.id}`)}>
        <AddIcon />
      </Td>
      <Td onClick={() => history.push(`/canteen/${item.id}/edit`)}>
        <Edit />
      </Td>
      <Td>
        <Delete
          onClick={() => {
            handleDelete()
          }}
        />
      </Td>
    </Row>
  )
}

export default Main

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px;
  cursor: pointer;

  &:nth-last-child(1) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(2) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(3) {
    text-align: right;
  }
`

const Edit = styled(EditIcon)`
  color: ${navy};
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
`

const AddIcon = styled(AddCircleIcon)`
  color: ${navy};
`
