import React from 'react'
import styled from 'styled-components'

import Food, { FoodType } from './Food'
import { backgroundGray, navy } from '../../layout/colors'

interface OfferType {
  food: FoodType
  date: string
  map: (offer: OfferType) => JSX.Element
}

interface Props {
  offer: OfferType[]
}

const OfferTable = ({ offer }: Props) => {
  function convertDate(dateString: string) {
    var date = new Date(dateString)
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
  }

  return (
    <>
      <Table>
        {offer.map((offer: OfferType) => {
          return (
            <>
              <TableHead>
                <Th>Datum</Th>
                <Th>{convertDate(offer.date)}</Th>
              </TableHead>
              <Food food={offer.food} />
            </>
          )
        })}
      </Table>
    </>
  )
}

export default OfferTable

const Table = styled.table`
  text-align: left;
  background-color: white;
  border-collapse: collapse;
  width: 100%;
`

const TableHead = styled.tr`
  background-color: ${backgroundGray};
`
const Th = styled.th`
  padding: 10px;
  color: white;
  background-color: ${navy};
`
