import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router'
import Select from 'react-select'

import { backgroundGray, navy } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import LoaderSpinner from '../../layout/loader'
import { InputValueStateType } from '../../constants/types'
import canteens from '../../queries/canteens'
import editCanteen from '../../mutations/editCanteen'
import canteenEdit from '../../queries/canteenEdit'
import users from '../../queries/users'
import { customStyles } from '../editUser/Main'

interface Props {
  userId: number
}

const Main = ({ userId }: Props) => {
  const history = useHistory()
  const { id }: { id: string } = useParams()

  if (id) {
    userId = parseInt(id)
  }

  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [error, setError] = useState(false)

  const [formState, setFormState] = useState<InputValueStateType>({
    name: { value: '', isInvalid: false },
    region: { value: '', isInvalid: false },
    admin: { value: '', isInvalid: false },
  })
  const refetchQueries = {
    refetchQueries: [{ query: canteens }, { query: canteenEdit, variables: { id: parseInt(id) } }],
  }
  const [reg] = useMutation(editCanteen, {
    ...refetchQueries,
  })

  function handleSubmit() {
    if (formState.name.isInvalid || formState.region.isInvalid) {
      return setError(true)
    }
    const data = {
      name: formState.name.value,
      region: formState.region.value,
      userId: parseInt(formState.admin.value),
      id: parseInt(id),
    }
    setError(false)
    history.replace('/canteens')
    return reg({ variables: { data } })
  }

  const getActualCanteen = useQuery(canteenEdit, {
    variables: { id: parseInt(id) },
  })

  const usersOptions = useQuery(users)

  useEffect(() => {
    setFormState({
      name: {
        value: getActualCanteen.loading
          ? ''
          : getActualCanteen.data.canteenEdit.currentCanteen.name,
        isInvalid: false,
      },
      region: {
        value: getActualCanteen.loading
          ? ''
          : getActualCanteen.data.canteenEdit.currentCanteen.region,
        isInvalid: false,
      },
      admin: {
        value: getActualCanteen.loading ? '' : getActualCanteen.data.canteenEdit.user.id.toString(),
        isInvalid: false,
      },
    })
    setSubmitButtonInactive(true)
  }, [getActualCanteen.data])

  useEffect(() => {
    if (formState.name.isInvalid || formState.region.isInvalid) setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string) {
    return !/^[\sa-zá-žA-ZÁ-Ž0-9._-]+$/.test(value)
  }

  if (getActualCanteen.loading || usersOptions.loading) {
    return <LoaderSpinner />
  }

  function selectOnChange(e?: any) {
    setFormState({
      ...formState,
      admin: {
        value: e ? e.value : '1',
        isInvalid: false,
      },
    })
  }

  const options = []

  for (const user of usersOptions.data.users) {
    options.push({ value: user.id.toString(), label: user.email })
  }

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>
              Úprava jídelny {getActualCanteen.data.canteenEdit.currentCanteen.name}
            </Heading>
            <TextInput
              type="text"
              placeholder="Název jídelny"
              name="text"
              value={formState.name.value}
              isInvalid={formState.name.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <TextInput
              type="text"
              placeholder="Kraj"
              value={formState.region.value}
              isInvalid={formState.region.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  region: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <Select
              isSearchable={true}
              styles={customStyles}
              options={options}
              value={options.find((obj) => obj.value === formState.admin.value)}
              //isInvalid={formState.rights.isInvalid}
              onChange={(e) => selectOnChange(e)}
              placeholder={'Vyberte správce jídelny'}
              noOptionsMessage={() => 'Žádný uživatel nenalezen'}
            />
            <div>{error && <LoginError>Hesla se neshodují</LoginError>}</div>
            <Submit inactive={submitButtonInactive} onClick={handleSubmit}>
              Upravit
            </Submit>
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/img/background_texture.jpg');
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
