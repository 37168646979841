import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { navy } from '../colors'

interface Props {
  mobile: boolean
}

const Buttons = ({ mobile }: Props) => {
  return (
    <>
      <ButtonOutlined mobile={mobile}>
        <OutlinedButtonLink to="/login">Přihlášení</OutlinedButtonLink>
      </ButtonOutlined>
      <Button mobile={mobile}>
        <ButtonLink to="/register">Registrace</ButtonLink>
      </Button>
    </>
  )
}
export default Buttons

const DefaultLink = styled(Link)`
  padding: 10px;
  text-decoration: none;
  color: black;
`

const ButtonLink = styled(DefaultLink)`
  padding: 10px 15px;
  color: white;
`

const OutlinedButtonLink = styled(ButtonLink)`
  color: black;
`

interface buttonProps {
  mobile: boolean
}

const Button = styled.div<buttonProps>`
  background-color: ${navy};
  color: white;
  display: flex;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border: 2px solid ${navy};
  border-radius: 5px;
  box-sizing: border-box;
  transition: 0.5s;
  margin-left: 20px;

  ${(props) =>
    props.mobile &&
    css`
      margin-left: 0px;
      margin: 10px 0;
      width: fit-content;
    `}

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }
`

const ButtonOutlined = styled(Button)`
  color: black;
  background-color: transparent;
  border: 2px solid ${navy};
`
