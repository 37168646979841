import React from 'react'
import Main from './Main'
import useLogin from '../../hooks/useLogin'
import { Redirect } from 'react-router'

const ForgotPassword = () => {
  const loginData = useLogin()
  if (loginData.user) {
    return <Redirect to="/" />
  }
  return <Main />
}

export default ForgotPassword
