import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'

import { backgroundGray } from '../../layout/colors'

export interface FoodType {
  name?: string
  id: number
}

interface Props {
  food: any
}

const Food = ({ food }: Props) => {
  const history = useHistory()

  return (
    <>
      {food.length !== 0 ? (
        food.map((pokrm: FoodType, i: number) => {
          return (
            <Row onClick={() => history.push(`/food/${pokrm.id}`)}>
              <Td>Oběd {i + 1}</Td>
              <Td>{pokrm.name}</Td>
            </Row>
          )
        })
      ) : (
        <Row>
          <Td>Nic v nabídce</Td>
          <Td></Td>
        </Row>
      )}
    </>
  )
}

export default Food

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px 10px;
  cursor: pointer;
  &:first-child {
    width: 100px;
  }
`
