import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Link } from 'react-router-dom'

import useLogin from '../../../hooks/useLogin'
import Buttons from '../Buttons'
import logout from '../../../mutations/logout'
import handleLogout from '../../handleLogout'

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const HamburgerMenu = ({ setOpen, open }: Props) => {
  const { user } = useLogin()
  const [logoutUser] = useMutation(logout)

  useEffect(() => {
    window.addEventListener('scroll', () => setOpen(false))
    return () => window.removeEventListener('scroll', () => setOpen(false))
  }, [])

  return (
    <Container open={open}>
      <Links>
        <NavLink onClick={() => setOpen(false)} to="/home">
          Seznam&nbsp;jídelen
        </NavLink>
        {user && user.adminLevel == '4' && (
          <NavLink onClick={() => setOpen(false)} to="/users">
            Uživatelé
          </NavLink>
        )}
        {user && user.adminLevel == '4' && (
          <NavLink onClick={() => setOpen(false)} to="/addCanteen">
            Přidat&nbsp;jídelnu
          </NavLink>
        )}
        {user && user.adminLevel == '4' && (
          <NavLink onClick={() => setOpen(false)} to="/canteens">
            Administrace&nbsp;jídelen
          </NavLink>
        )}
        {user && parseInt(user.adminLevel) >= 2 && (
          <NavLink onClick={() => setOpen(false)} to="/food">
            Administrace&nbsp;pokrmů
          </NavLink>
        )}
        {user && parseInt(user.adminLevel) >= 2 && (
          <NavLink onClick={() => setOpen(false)} to="/photos">
            Schválení&nbsp;fotografií
          </NavLink>
        )}
        {user && (
          <NavLink onClick={() => setOpen(false)} to="/editUser">
            <AccountIcon />
            {user.userName}
          </NavLink>
        )}
        {user && (
          <Href onClick={() => handleLogout(logoutUser)}>
            <LogoutIcon />
            Odhlásit&nbsp;se
          </Href>
        )}
        {!user && <Buttons mobile={true} />}
      </Links>
    </Container>
  )
}
export default HamburgerMenu

interface containerProps {
  open: boolean
}

const Container = styled.div<containerProps>`
  min-height: 100vh;
  text-align: left;
  position: absolute;
  padding-top: 138.88px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: width 0.3s ease-in-out;
  background-color: white;
  display: flex;
  overflow: hidden;
  z-index: 999;

  width: ${(props) => (props.open ? '70vw' : '0')};

  @media (min-width: 378px) {
    padding-top: 100.88px;
  }

  @media (min-width: 400px) {
    width: ${(props) => (props.open ? '50vw' : '0')};
  }

  @media (min-width: 600px) {
    width: ${(props) => (props.open ? '30vw' : '0')};
  }
`

const Links = styled.div`
  flex: 2;
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`

const NavLink = styled(Link)`
  padding: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`
const AccountIcon = styled(AccountCircleIcon)`
  margin-right: 5px;
`

const LogoutIcon = styled(ExitToAppIcon)`
  margin-right: 5px;
`

const Href = styled.a`
  padding: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`
