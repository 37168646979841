import React from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import photoAdmin from '../../queries/photoAdmin'
import approvePhoto from '../../mutations/approvePhoto'
import { navy } from '../../layout/colors'
import removePhoto from '../../mutations/removePhoto'

export interface ApproveFoodType {
  photos: ApproveFoodImageType[]
  pokrm: string
}

interface ApproveFoodImageType {
  id: number
  s3url: string
}

interface Props {
  item: ApproveFoodType
}

const Main = ({ item }: Props) => {
  const refetchQueries = { refetchQueries: [{ query: photoAdmin }] }
  const [req] = useMutation(approvePhoto, {
    ...refetchQueries,
  })

  const [req2] = useMutation(removePhoto, {
    ...refetchQueries,
  })

  return (
    <Container>
      <h3>{item.pokrm}</h3>
      <ContentContainer>
        {item.photos.length ? (
          item.photos.map((item: ApproveFoodImageType) => (
            <ImageContainer>
              <Image url={item.s3url} />
              <Approve onClick={() => req({ variables: { id: item.id } })}>Schválit</Approve>

              <Approve onClick={() => req2({ variables: { id: item.id } })}>Odstranit</Approve>
            </ImageContainer>
          ))
        ) : (
          <p>Žadné fotografie ke schválení</p>
        )}
      </ContentContainer>
    </Container>
  )
}

export default Main

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;
  width: 100%;

  @media (min-width: 530px) {
    flex-direction: row;
  }
`

const ImageContainer = styled.div`
  flex: 1;
  max-width: 420px;
  text-align: center;
  margin: 20px 10px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }

  @media (min-width: 530px) {
    flex: 1 0 420px;
  }
`

interface ImageProps {
  url: string
}

const Image = styled.div<ImageProps>`
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.url});
  height: 200px;
  flex-basis: min-content;
  max-width: 420px;
  margin: 20px 25px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;

  @media (min-width: 530px) {
    height: 300px;
  }
`

const Approve = styled.button`
  border: none;
  cursor: pointer;
  margin: 20px 10px;
  padding: 10px 20px;
  background-color: ${navy};
  align-items: center;
  color: white;
  display: inline-flex;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  width: min-content;
  justify-content: space-between;
  transition: 0.5s;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }
`
