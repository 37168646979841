import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Props from './Props'
import useLogin from '../hooks/useLogin'

interface CustomAuthorizedRouteProps extends Props {
  adminLevel: string
}

export default ({ Component, adminLevel, exact, ...rest }: CustomAuthorizedRouteProps) => {
  const { user } = useLogin()

  return (
    <Route
      {...rest}
      exact={exact}
      render={() =>
        user && user.adminLevel >= adminLevel ? <Component /> : <Redirect to="/login" />
      }
    />
  )
}
