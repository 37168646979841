import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from './screens/home'
import login from './screens/login'
import Canteen from './screens/canteen/Main'
import Header from './layout/Header'
import NoOverflowContainer from './layout/NoOverflowContainer'
import Footer from './layout/Footer'
import addCanteen from './screens/addCanteen/Main'
import Register from './screens/register'
import NotFound from './screens/NotFound'
import LoginRoute from './Routes/LoginRoute'
import AuthorizedRoute from './Routes/AuthorizedRoute'
import Users from './screens/users'
import CustomAuthorizedRoute from './Routes/CustomAuthorizedRoute'
import editUser from './screens/editUser'
import ForgotPassword from './screens/forgotPassword'
import RenewPassword from './screens/renewPassword'
import CanteensAdmin from './screens/canteensAdmin'
import Food from './screens/food/Main'
import AddPhoto from './screens/addPhoto/Main'
import EditCanteen from './screens/editCanteen'
import FoodAdmin from './screens/foodAdmin'
import EditFood from './screens/editFood'
import PhotoAdmin from './screens/photoAdmin'
import AddOffer from './screens/addOffer/Main'
import AddFood from './screens/addFood/Main'

function App() {
  return (
    <NoOverflowContainer>
      <Header />
      <Switch>
        <LoginRoute path="/login" Component={login} />
        <CustomAuthorizedRoute Component={Users} adminLevel={'4'} exact={true} path="/users" />
        <CustomAuthorizedRoute
          Component={editUser}
          adminLevel={'4'}
          exact={true}
          path="/users/:id/edit"
        />
        <AuthorizedRoute Component={editUser} path="/editUser" exact={true} />
        {/* <Route path="/home" component={Home} /> */}
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/renewPassword/:userId/:token" component={RenewPassword} />
        <Route path="/login" component={login} />
        <Route path="/register" component={Register} />
        <CustomAuthorizedRoute
          Component={EditCanteen}
          adminLevel={'4'}
          exact={true}
          path="/canteen/:id/edit"
        />
        <Route path="/canteen/:id" component={Canteen} />
        <CustomAuthorizedRoute
          path="/addCanteen"
          adminLevel={'4'}
          exact={true}
          Component={addCanteen}
        />
        <CustomAuthorizedRoute
          path="/canteens"
          adminLevel={'4'}
          exact={true}
          Component={CanteensAdmin}
        />
        <CustomAuthorizedRoute
          path="/food/:id/addPhoto"
          adminLevel={'1'}
          exact={true}
          Component={AddPhoto}
        />
        <CustomAuthorizedRoute
          path="/food/:id/edit"
          adminLevel={'2'}
          exact={true}
          Component={EditFood}
        />
        <Route path="/food/:id" component={Food} />
        <CustomAuthorizedRoute path="/food" adminLevel={'2'} exact={true} Component={FoodAdmin} />
        <CustomAuthorizedRoute
          path="/addOffer/:id"
          adminLevel={'2'}
          exact={true}
          Component={AddOffer}
        />
        <CustomAuthorizedRoute
          path="/addFood/:id"
          adminLevel={'2'}
          exact={true}
          Component={AddFood}
        />
        <CustomAuthorizedRoute
          path="/photos"
          adminLevel={'2'}
          exact={true}
          Component={PhotoAdmin}
        />
        {/*  <Route exact path="/" component={Home} /> */}
        <Redirect from="/home" to="/canteen/1" />
        <Redirect from="/" to="/canteen/1" />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </NoOverflowContainer>
  )
}

export default App
