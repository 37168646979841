import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { navy } from './colors'

interface Props {
  linkText: string
  href: string
}

const FormInfoText = ({ href, linkText }: Props) => {
  return (
    <Info>
      <InfoTetx>
        <InfoLink to={href}>{linkText}</InfoLink>
      </InfoTetx>
    </Info>
  )
}

export default FormInfoText

const Info = styled.div``

const InfoTetx = styled.p`
  text-align: center;
  margin-bottom: 0;
`

const InfoLink = styled(Link)`
  color: ${navy};
`
