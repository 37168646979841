import React, { useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router'

import LoaderSpinner from '../../layout/loader'
import food from '../../queries/food'
import Search from '../../layout/Search'
import { FoodType } from './Food'

interface FoodImage extends FoodType {
  img: string
}

const OfferSection = () => {
  const history = useHistory()
  const { id }: { id: string } = useParams()
  const [searchValue, setSearchValue] = useState('')

  const { loading, data } = useQuery(food, {
    variables: { id: parseInt(id) },
  })

  if (loading) {
    return <LoaderSpinner />
  }

  function search(items: any) {
    return items.filter((item: any) =>
      ['name'].some((newItem: any) =>
        item[newItem]
          ? item[newItem].toString().toLowerCase().includes(searchValue.toLowerCase())
          : false
      )
    )
  }

  return (
    <>
      <SearchContainer>
        <SearchFood
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          clearSearch={() => setSearchValue('')}
          placeholder={`např.: ${data.canteenFood[0] ? data.canteenFood[0].name : 'Guláš'}`}
        />
      </SearchContainer>

      <Container>
        {data &&
          (searchValue.length > 0 ? search(data.canteenFood) : data.canteenFood).map(
            (food: FoodImage) => {
              return (
                <FoodItemContainer onClick={() => history.push(`/food/${food.id}`)}>
                  <Image url={food.img ? food.img : '/img/placeholder.png'} />
                  <p>{food.name}</p>
                </FoodItemContainer>
              )
            }
          )}
      </Container>
    </>
  )
}

export default OfferSection

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  flex-direction: column;

  @media (min-width: 530px) {
    flex-direction: row;
  }
`

const FoodItemContainer = styled.div`
  flex: 1;
  max-width: 420px;
  text-align: center;
  cursor: pointer;
  margin: 20px 10px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }

  @media (min-width: 530px) {
    flex: 1 0 420px;
  }
`

interface ImageProps {
  url: string
}

const Image = styled.div<ImageProps>`
  background-position: center;
  background-size: cover;
  background-image: url(${(props) => props.url});
  height: 200px;
  flex-basis: min-content;
  max-width: 420px;
  margin: 20px 25px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;

  @media (min-width: 530px) {
    height: 300px;
  }
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SearchFood = styled(Search)`
  margin: 0;
`
