import React from 'react'
import Main from './Main'
import useLogin from '../../hooks/useLogin'
import { Redirect } from 'react-router'

const EditUser = () => {
  const loginData = useLogin()
  if (!loginData.user) {
    return <Redirect to="/login" />
  }

  const userId = loginData.user.id
  return <Main userId={userId} />
}

export default EditUser
