import React from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'
import { backgroundGray, navy } from './colors'

const LoaderSpinner = () => {
  return (
    <Container>
      <Loader type="Oval" color={navy} height={150} width={150} />
    </Container>
  )
}

export default LoaderSpinner

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  align-items: center;
  background-color: ${backgroundGray};
  z-index: 999999;
`
