import { gql } from '@apollo/client'

interface Canteens {
  id: number
  firstName: String
  lastName: String
}

export interface CanteenType {
  city: string
  items: Canteens
}

export default gql`
  query Query {
    canteens {
      id
      region
      name
    }
  }
`
