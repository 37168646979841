import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import imageCompression from 'browser-image-compression'
import { useHistory, useParams } from 'react-router'

import { navy, backgroundGray } from '../../layout/colors'
import useLogin from '../../hooks/useLogin'

const AddPhoto = () => {
  const { id }: { id: string } = useParams()
  const options = {
    maxSizeMB: 1.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    initialQuality: 0.7,
  }
  const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg']

  const ref = useRef<HTMLButtonElement>(null)
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [err, setError] = useState(false)
  const [file, setFile] = useState<FileList | null>()
  const loginData = useLogin()
  const history = useHistory()

  async function handleSubmit() {
    if (!file || !file[0] || !handleValidation()) {
      return setError(true)
    }
    setError(false)
    const compressedFile = await imageCompression(file[0], options)

    if (!loginData.token) {
      return setError(true)
    }
    const data = new FormData()
    data.append('file', compressedFile)
    data.append('id', id)
    // @ts-ignore
    data.append('token', loginData.token)

    return fetch(`${process.env.REACT_APP_API_URL}upload`, {
      method: 'POST',
      body: data,
    })
      .then(() => {
        history.replace(`/food/${id}`)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  useEffect(() => {
    if (!handleValidation()) {
      setSubmitButtonInactive(true)
      setError(true)
    } else {
      setSubmitButtonInactive(false)
      setError(false)
    }
    if (!file) {
      setError(false)
    }
  }, [file])

  function handleValidation() {
    return file && allowedFormats.includes(file[0].type)
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  return (
    <Container100vh>
      <Container>
        <Form>
          <Heading>Přidání fotografie jídla</Heading>
          <input type="file" name="" id="" onChange={(e) => setFile(e.target && e.target.files)} />
          <div>{err && <LoginError>Nepodporovaný formát</LoginError>}</div>
          <Submit ref={ref} inactive={submitButtonInactive} onClick={handleSubmit}>
            Přidat fotku
          </Submit>
        </Form>
      </Container>
    </Container100vh>
  )
}

export default AddPhoto

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/img/background_texture.jpg');
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const Form = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
