import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Props from './Props'
import useLogin from '../hooks/useLogin'

export default ({ Component, ...rest }: Props) => {
  const loginData = useLogin()

  return <Route {...rest} render={() => (loginData.user ? <Redirect to={`/`} /> : <Component />)} />
}
