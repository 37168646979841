import { gql } from '@apollo/client'

interface Canteens {
  id: number
  firstName: String
  lastName: String
}

export interface CanteenType {
  city: string
  items: Canteens
}

export default gql`
  query Query($id: Int!) {
    canteen(id: $id) {
      currentCanteen {
        id
        name
        region
        userId
      }
      offer {
        id
        date
        food {
          id
          name
        }
      }
    }
  }
`
