import { gql } from 'apollo-boost'

export default gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        userName
        adminLevel
        email
        createdAt
        updatedAt
      }
    }
  }
`
