import { gql } from 'apollo-boost'

export default gql`
  query Query($userId: Int!) {
    user(userId: $userId) {
      userName
      email
      adminLevel
      id
    }
  }
`
