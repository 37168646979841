import React from 'react'
import styled from 'styled-components'
import MobileMenu from './MobileMenu/Main'
import DesktopMenu from './DesktopMenu/Main'
import MediaQuery from 'react-responsive'
import useLogin from '../../hooks/useLogin'
import { Link } from 'react-router-dom'

const Main = () => {
  const { user } = useLogin()

  return (
    <Container>
      <MainHeadingLink to="/home">
        <MainHeading>Hodnocení jídelen</MainHeading>
      </MainHeadingLink>
      <MediaQuery minWidth={user && parseInt(user.adminLevel) >= 4 ? 1791 : 1380}>
        <DesktopMenu />
      </MediaQuery>
      <MediaQuery maxWidth={user && parseInt(user.adminLevel) >= 4 ? 1790 : 1379}>
        <MobileMenu />
      </MediaQuery>
    </Container>
  )
}

export default Main

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10%;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  text-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
`

const MainHeading = styled.h1`
  z-index: 1000;
`

const MainHeadingLink = styled(Link)`
  z-index: 1000;
  text-decoration: none;
  color: black;
`
