import React from 'react'
import styled from 'styled-components'
import { navy } from './colors'

interface Props {
  child: any
}

const ColorButton = ({ child }: Props) => {
  return <Button>{child}</Button>
}
export default ColorButton

const Button = styled.div`
  padding: 10px;
  background-color: ${navy};
  align-items: center;
  color: white;
  display: inline-flex;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  width: 95%;
  justify-content: space-between;
  transition: 0.5s;
  transform: translateZ(0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;

  @media (min-width: 500px) {
    width: 180px;
  }

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }
`
