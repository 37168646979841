import { gql } from '@apollo/client'

interface Canteens {
  id: number
  firstName: String
  lastName: String
}

export interface CanteenType {
  city: string
  items: Canteens
}

export default gql`
  query Query($id: Int!) {
    canteenFood(id: $id) {
      id
      name
      img
    }
  }
`
