import React, { useState } from 'react'
import styled from 'styled-components'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import HamburgerMenu from './HamburgerMenu'

const MobileMenu = () => {
  const [open, setOpen] = useState(false)
  return (
    <Container>
      {open ? <Close onClick={() => setOpen(false)} /> : <OpenIcon onClick={() => setOpen(true)} />}
      <HamburgerMenu setOpen={setOpen} open={open} />
    </Container>
  )
}
export default MobileMenu

interface OpenIconProps {
  onClick: () => void
}

const Container = styled.div`
  display: flex;
  margin-bottom: -6px;
  overflow-x: unset;
`

const OpenIcon = styled(MenuIcon)<OpenIconProps>`
  cursor: pointer;
  z-index: 1000;
`

interface CloseProps {
  onClick: () => void
}

const Close = styled(CloseIcon)<CloseProps>`
  cursor: pointer;
  z-index: 1000;
`
