import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'

import { navy, backgroundGray } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import FormInfoText from '../../layout/FormInfoText'
import register from '../../mutations/register'
import { InputValueStateType } from '../../constants/types'

const Main = () => {
  const ref = useRef<HTMLButtonElement>(null)
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [err, setError] = useState(false)

  const [formState, setFormState] = useState<InputValueStateType>({
    email: { value: '', isInvalid: false },
    password: { value: '', isInvalid: false },
    passwordAgain: { value: '', isInvalid: false },
    userName: { value: '', isInvalid: false },
  })

  const [reg] = useMutation(register, {
    onCompleted({ register }) {
      localStorage.setItem('loginData', JSON.stringify(register))
      window.location.replace('/home')
    },
  })

  function handleSubmit() {
    if (
      formState.userName.isInvalid ||
      formState.email.isInvalid ||
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    ) {
      return setError(true)
    }
    const data = {
      userName: formState.userName.value,
      password: formState.password.value,
      email: formState.email.value,
    }
    setError(false)
    return reg({ variables: { data } })
  }

  useEffect(() => {
    if (formState.password.value !== formState.passwordAgain.value) {
      return setError(true)
    }
    return setError(false)
  }, [formState.passwordAgain.value, formState.password.value])

  useEffect(() => {
    if (
      formState.userName.isInvalid ||
      formState.email.isInvalid ||
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    )
      setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string, type: string) {
    if (type === 'password') {
      return !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)
    }
    if (type === 'text') {
      return !/^[a-zá-žA-ZÁ-Ž0-9._-]+$/.test(value)
    }
    if (type === 'email') {
      return !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    }
    return false
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>Registrace</Heading>
            <TextInput
              type="email"
              placeholder="Váš email"
              name="email"
              value={formState.email.value}
              isInvalid={formState.email.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <TextInput
              type="text"
              placeholder="Uživatelské jméno"
              value={formState.userName.value}
              isInvalid={formState.userName.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  userName: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <TextInput
              type="password"
              placeholder="Heslo"
              value={formState.password.value}
              isInvalid={formState.password.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  password: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            {formState.password.isInvalid && (
              <PasswordHint>
                Heslo musí obshaovat alespoň 8 znaků, malá a velká písmena a číslo
              </PasswordHint>
            )}
            <TextInput
              type="password"
              placeholder="Heslo znovu"
              value={formState.passwordAgain.value}
              isInvalid={formState.passwordAgain.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  passwordAgain: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <div>{err && <LoginError>Hesla se neshodují</LoginError>}</div>
            <Submit ref={ref} inactive={submitButtonInactive} onClick={handleSubmit}>
              Zaregistrovat se
            </Submit>
            <FormInfoText linkText={'Máte již účet?'} href={'/login'} />
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/img/background_texture.jpg');
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`
interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`

const PasswordHint = styled(LoginError)`
  position: inherit;
`
