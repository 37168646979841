import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router'
import Select from 'react-select'

import { backgroundGray, navy } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import useLogin from '../../hooks/useLogin'
import user from '../../queries/user'
import editUser from '../../mutations/editUser'
import LoaderSpinner from '../../layout/loader'
import { InputValueStateType } from '../../constants/types'

interface Props {
  userId: number
}

export const customStyles = {
  control: () => ({
    margin: ' 20px 0',
    border: '2px solid transparent',
    outline: 'none',
    padding: '5px',
    backgroundColor: '#F5F5F5',
    borderRadius: '5px',
    boxShadow: ' 0rem 0.5rem 2rem rgb(0 0 0 / 20%)',
    display: 'flex',
    fontSize: '13.3333px',
  }),
  menu: (provided: any) => ({
    ...provided,
    myrginTop: '-20px',
    backgroundColor: '#F5F5F5',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#005471' : '#F5F5F5',
    color: state.isFocused ? '#F5F5F5' : '#005471',
  }),
}

const Main = ({ userId }: Props) => {
  const loginData = useLogin()
  const history = useHistory()
  const { id }: { id: string } = useParams()

  if (id) {
    userId = parseInt(id)
  }

  const options = [
    { value: '0', label: 'Ban' },
    { value: '1', label: 'Uživatel' },
    { value: '2', label: 'Správce jídelny' },
    { value: '4', label: 'Admin' },
  ]

  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [error, setError] = useState(false)
  const [formState, setFormState] = useState<InputValueStateType>({
    email: { value: '', isInvalid: true },
    password: { value: '', isInvalid: true },
    passwordAgain: { value: '', isInvalid: true },
    userName: { value: '', isInvalid: true },
    rights: { value: '', isInvalid: true },
  })

  const refetchQueries = { refetchQueries: [{ query: user, variables: { userId } }] }
  const [editCurrentUser] = useMutation(editUser, {
    ...refetchQueries,
  })

  function handleSubmit() {
    if (
      formState.userName.isInvalid ||
      formState.email.isInvalid ||
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    ) {
      return setError(true)
    }
    const data = {
      id: userId,
      userName: formState.userName.value,
      password: formState.password.value,
      email: formState.email.value,
      adminLevel: getActualUserData.data.user.adminLevel,
      userAdminLevel: parseInt(formState.rights.value),
    }
    setError(false)
    history.replace('/users')
    return editCurrentUser({ variables: { data } })
  }

  const getActualUserData = useQuery(user, {
    variables: { userId },
  })

  useEffect(() => {
    setFormState({
      email: {
        value: getActualUserData.loading ? '' : getActualUserData.data.user.email,
        isInvalid: false,
      },
      password: { value: '', isInvalid: false },
      passwordAgain: { value: '', isInvalid: false },
      userName: {
        value: getActualUserData.loading ? '' : getActualUserData.data.user.userName,
        isInvalid: false,
      },
      rights: {
        value: getActualUserData.loading ? '' : getActualUserData.data.user.adminLevel.toString(),
        isInvalid: false,
      },
    })
    setSubmitButtonInactive(true)
  }, [getActualUserData.data])

  useEffect(() => {
    if (formState.password.value !== formState.passwordAgain.value) {
      return setError(true)
    }
    return setError(false)
  }, [formState.passwordAgain.value, formState.password.value])

  useEffect(() => {
    if (
      formState.userName.isInvalid ||
      formState.email.isInvalid ||
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    )
      setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string, type: string) {
    if (type === 'password') {
      return !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)
    }
    if (type === 'text') {
      return !/^[a-zá-žA-ZÁ-Ž0-9._-]+$/.test(value)
    }
    if (type === 'email') {
      return !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    }
    return false
  }

  function selectOnChange(e?: any) {
    setFormState({
      ...formState,
      rights: {
        value: e ? e.value : '1',
        isInvalid: handleValidation(e ? e.value : '1', 'select'),
      },
    })
  }

  if (getActualUserData.loading) {
    return <LoaderSpinner />
  }

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>Úprava uživatele {getActualUserData.data.user.userName}</Heading>
            <TextInput
              type="email"
              placeholder="Váš email"
              name="email"
              value={formState.email.value}
              isInvalid={formState.email.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <TextInput
              type="text"
              placeholder="Uživatelské jméno"
              value={formState.userName.value}
              isInvalid={formState.userName.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  userName: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            {loginData.user?.adminLevel == '4' && (
              <Select
                isSearchable={false}
                styles={customStyles}
                options={options}
                value={options.find((obj) => obj.value === formState.rights.value)}
                //isInvalid={formState.rights.isInvalid}
                onChange={(e) => selectOnChange(e)}
              />
            )}
            <TextInput
              type="password"
              placeholder="Heslo"
              value={formState.password.value}
              isInvalid={formState.password.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  password: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <TextInput
              type="password"
              placeholder="Heslo znovu"
              value={formState.passwordAgain.value}
              isInvalid={formState.passwordAgain.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  passwordAgain: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />{' '}
            <div>{error && <LoginError>Hesla se neshodují</LoginError>}</div>
            <Submit inactive={submitButtonInactive} onClick={handleSubmit}>
              Upravit
            </Submit>
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('/img/background_texture.jpg');
  background-position: center;
  background-size: cover;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

interface SubmitProps {
  inactive: boolean
}

const Submit = styled.button<SubmitProps>`
  margin: 20px 0;
  border: none;
  padding: 15px;
  background-color: ${navy};
  color: white;
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  outline-color: #005471;
  cursor: pointer;
  ${(props) =>
    props.inactive &&
    css`
      pointer-events: none;
      cursor: default;
      color: ${backgroundGray};
      background-color: #00394d;
    `}
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
