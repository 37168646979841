import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Props from './Props'
import useLogin from '../hooks/useLogin'

export default ({ Component, exact, ...rest }: Props) => {
  const { user } = useLogin()

  return (
    <Route
      {...rest}
      exact={exact}
      render={() => (user ? <Component /> : <Redirect to="/login" />)}
    />
  )
}
