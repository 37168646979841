import { gql } from 'apollo-boost'

export default gql`
  mutation register($data: RegisterInput!) {
    register(data: $data) {
      token
      user {
        id
        userName
        adminLevel
        email
        createdAt
        updatedAt
      }
    }
  }
`
