import React from 'react'
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { useMutation } from '@apollo/client'

import users, { UserType } from '../../queries/users'
import { backgroundGray, navy } from '../../layout/colors'
import { useHistory } from 'react-router'
import deleteUser from '../../mutations/deleteUser'

interface Props {
  item: UserType
}

const Main = ({ item }: Props) => {
  const history = useHistory()
  const rights = ['ban', 'uživatel', 'správce jídelny', '', 'admin']

  const refetchQueries = { refetchQueries: [{ query: users }] }
  const [req] = useMutation(deleteUser, {
    ...refetchQueries,
  })

  function handleDelete() {
    return req({ variables: { id: item.id } })
  }

  return (
    <Row>
      <Td>{item.id}</Td>
      <Td>{rights[parseInt(item.adminLevel)]}</Td>
      <Td>{item.userName}</Td>
      <Td>{item.email}</Td>
      <Td>
        <Edit onClick={() => history.push(`/users/${item.id}/edit`)} />
      </Td>
      <Td>
        <Delete
          onClick={() => {
            handleDelete()
          }}
        />
      </Td>
    </Row>
  )
}

export default Main

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px;
  cursor: pointer;

  &:nth-last-child(1) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(2) {
    text-align: right;
    width: 34px;
  }
`

const Edit = styled(EditIcon)`
  color: ${navy};
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
`
