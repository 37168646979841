import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Footer = () => {
  return (
    <Container>
      <Links>
        <NavLink to="/home">Jídelna Dašická</NavLink>
        <Spacer>|</Spacer>
        <Href target={'_blank'} href="https://www.gypce.cz/">
          gypce.cz
        </Href>
        <Spacer>|</Spacer>
        <Href
          href="https://www.strava.cz/Strava/Stravnik/Jidelnicky?zarizeni=0304"
          target={'_blank'}
        >
          strava.cz
        </Href>
      </Links>
      <Copyright>
        &copy; &nbsp;
        <MailLink href="">Hodnocení jídelen</MailLink>
        &nbsp;{new Date().getFullYear()}
      </Copyright>
    </Container>
  )
}

export default Footer

const Container = styled.div`
  padding: 10px 10%;
  padding-top: 30px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
`

const Copyright = styled.p`
  text-align: center;
  font-weight: bold;
`

const MailLink = styled.a`
  color: black;
  text-decoration: none;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
`

const NavLink = styled(Link)`
  color: black;
`

const Href = styled.a`
  color: black;
`

const Spacer = styled.span`
  margin: 0 15px;
`
