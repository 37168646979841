import { gql } from 'apollo-boost'

export interface UserType {
  id: number
  email?: string
  userName: string
  adminLevel: string
}

export default gql`
  query Query {
    users {
      id
      email
      adminLevel
      userName
    }
  }
`
